#amenities {
    ul.amenities {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px 60px;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            padding: 0 0 15px 0;
            margin: 0;
            border-bottom: 1px solid var(--rentfetch-black-5-percent);
        }
    }
}
