body {
    overflow-x: hidden;
}

.rent-fetch-property-search-default-layout {
    margin-left: calc(50% - 50dvw) !important;
    margin-right: calc(50% - 50dvw) !important;
    max-width: 100dvw !important;
    width: 100dvw !important;
    position: sticky;
    top: 0; /* Add this line to stick the layout container to the top */
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    z-index: 100;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }

    .map-container {
        background-color: var(--rentfetch-base);
        position: sticky;
        height: 100vh;
        top: 0; /* Add this line to stick the map container to the top */
        z-index: 1; /* Add this line to ensure the map container is above other elements */

        @media (max-width: 1200px) {
            order: 0;
            height: 400px;
            position: static;
        }

        @include mobile {
            height: 250px;
        }

        #map {
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }

    .filters-and-properties-container {
        position: relative;
        background-color: #f7f7f7;
        margin: 0;

        @media (max-width: 1200px) {
            order: 1;
        }

        .filters-wrap {
            position: sticky;
            top: 0;
            background-color: white;
            padding: 30px;
            border-bottom: 1px solid rgba(black, 0.1);
            border-top: 1px solid rgba(black, 0.1);
            z-index: 20;
            margin: 0;

            @include notdesktop {
                position: static;
                padding: 15px;
            }

            .filter-details {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        #response {
            padding: 30px;

            @include mobile {
                padding: 20px;
            }
        }
    }
}
