.floorplangrid .filters {
    text-align: center;
    margin-bottom: 30px;

    li {
        list-style: none;
        margin: 4px;
        display: inline-block;

        @include mobile {
            width: 100%;
            display: block;
            margin: 4px 0;
        }
    }

    a {
        padding: 8px 20px;
        // font-size: 14px;
        background-color: #111;
        color: white;
        text-decoration: none;
        transition: .3s all ease;
        border-radius: 3px;
        display: inline-block;

        @include mobile {
            width: 100%;
            display: block;
        }

        &.active {
            background-color: rgba(#111, 0.7);
        }
    }
}