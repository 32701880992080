.floorplangrid {

    .is-admin {
        &:before {
            display: block;
            content: "";
            inset: 0;
            position: absolute;
            z-index: 1;
        }
    }

    .floorplangrid__content {
        padding: 15px;

        > :last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 5px;
            line-height: 1.4em;
        }
    }

    h3.floorplangrid__title {
        margin-bottom: 5px;
    }

    .floorplangrid__info {
        >span {
            display: inline-block;


            &:after {
                content: '\00b7';
                margin-left: 5px;
                margin-right: 5px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .floorplangrid__rentrange {
        font-weight: bold;
    }

    .buttons {
        padding-top: 8px;
        width: calc(100% + 8px);
        margin-left: -4px;
        margin-right: -4px;

        @include mobile {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
}