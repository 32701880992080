.rent-fetch_page_rent-fetch-shortcodes {
	span.shortcode {
		display: inline-block;
		padding: 8px 15px;
		background-color: white;
		margin: 0 5px 0 0;
		box-shadow: 2px 2px 5px 0 rgba(black, 0.07);
		border: 1px solid rgba(black, 0.12);
		transition: 0.3s all ease;
		cursor: pointer;
		position: relative;
		transition: 0.3s all ease;

		&:hover {
			box-shadow: 2px 2px 5px 0 rgba(black, 0.2);
		}

		&:before {
			transition: 0.3 sll all ease;
			opacity: 0;
		}
	}

	span.shortcode.copied {
		&:before {
			content: 'Copied!';
			position: absolute;
			inset: 0;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
		}
	}

	h2 {
		margin-top: 25px;
		padding-top: 25px;
		border-top: 1px solid #ccc;
		font-size: 20px;
	}
}
