.floorplans-simple-grid {
	// display: grid;
	// grid-template-columns: repeat(3, 1fr);
	// gap: 25px;

	// @include tablet {
	// 	grid-template-columns: repeat(2, 1fr);
	// }

	// @include mobile {
	// 	grid-template-columns: repeat(1, 1fr);
	// }
}
